<template>
    <div>
        <div style="margin-bottom: 5px">
            <el-input
                v-model="name"
                placeholder="请输入用户名"
                suffix-icon="el-icon-a-042"
                style="width: 200px"
                @keyup.enter.native="loadPost"
            ></el-input>

            <el-button
                type="primary"
                style="margin-left: 5px; font-size: 18px"
                @click="loadPost"
            >
                查询</el-button
            >
            <el-button
                type="warning"
                style="font-size: 18px"
                @click="resetParam"
            >
                重置</el-button
            >
        </div>
        <el-table
            :data="tableData"
            :header-cell-style="{ background: '#f2f5fc', color: '#555555' }"
            border
        >
            <el-table-column prop="id" label="ID" width="60"> </el-table-column>
            <el-table-column prop="username" label="申请人" width="150">
            </el-table-column>
            <el-table-column prop="createTime" label="操作时间" width="180">
            </el-table-column>
            <el-table-column prop="remark" label="备注">
            </el-table-column>
        </el-table>
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-sizes="[5, 10, 20, 30]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
        >
        </el-pagination>
    </div>
</template>

<script>
export default {
    name: "RecordManage",
    data() {
        return {
            user: JSON.parse(sessionStorage.getItem("CurUser")),
            storageData: [],
            goodstypeData: [],
            tableData: [],
            pageSize: 10,
            pageNum: 1,
            total: 0,
            name: "",
            storage: "",
            goodstype: "",
            centerDialogVisible: false,
            form: {
                id: "",
                name: "",
                storage: "",
                goodstype: "",
                count: "",
                remark: "",
            },
        };
    },
    methods: {
        formatStorage(row) {
            let temp = this.storageData.find((item) => {
                return item.id == row.storage;
            });

            return temp && temp.name;
        },
        formatGoodstype(row) {
            let temp = this.goodstypeData.find((item) => {
                return item.id == row.goodstype;
            });

            return temp && temp.name;
        },
        resetForm() {
            this.$refs.form.resetFields();
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.pageNum = 1;
            this.pageSize = val;
            this.loadPost();
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.pageNum = val;
            this.loadPost();
        },
        resetParam() {
            this.name = "";
            this.loadPost();
        },
        loadPost() {
            this.$axios
                .post(this.$httpUrl + "/record/listPage", {
                    pageSize: this.pageSize,
                    pageNum: this.pageNum,
                    param: {
                        name: this.name,
                    },
                })
                .then((res) => res.data)
                .then((res) => {
                    console.log(res);
                    if (res.code == 200) {
                        this.tableData = res.data;
                        this.total = res.total;
                    } else {
                        alert("获取数据失败");
                    }
                });
        },
    },
    beforeMount() {
        this.loadPost();
    },
};
</script>

<style scoped>
</style>
